<template>
   <div class="house" id="house">

   </div>
</template>

<script setup>
import { onMounted } from "vue"
import * as THREE from "three"
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js"

onMounted(() => {
   const scene = new THREE.Scene()
   const size = 36
   const width = 750
   const height = 600

   var night_time = true;
   var night_timer = 0

   const camera = new THREE.OrthographicCamera(
      width / size / -2,
      width / size / 2,
      height / size / 2,
      height / size / -2,
      1,
      1000
   )

   const renderer = new THREE.WebGLRenderer()
   renderer.setPixelRatio(0.17)
   renderer.setClearAlpha(0)
   renderer.shadowMap.enabled = true
   renderer.outputEncoding = THREE.sRGBEncoding;
   renderer.setSize(width, height)

   document.querySelector(".house").appendChild(renderer.domElement)

   const loader = new GLTFLoader()
   var window_mat
   var window_original_color
   var window_glow_color = new THREE.Color(0xff9c33)

   var house

   loader.load("/house.glb", (gltf) => {
      gltf.scene.traverse((c) => {
         if (c.isMesh) {
            c.castShadow = true;
            c.receiveShadow = true;
         }
      })
      gltf.scene.rotation.y = degrees_to_radians(-90)

      window_mat = gltf.scene.children[0].children[3].material
      window_original_color = window_mat.color

      // window material
      if (night_time) {
         window_mat.emissive = window_glow_color
      }

      house = gltf.scene

      scene.add(house)
   }, undefined, (err) => {
      console.log(err)
   })

   const ground_geometry = new THREE.BoxGeometry(5, 1.5, 5)
   const ground_mat = new THREE.MeshStandardMaterial({ color: 0x83b04a })
   const ground = new THREE.Mesh(ground_geometry, ground_mat)
   ground.position.set(0, -1.5 / 2, 0)
   ground.receiveShadow = true
   scene.add(ground)

   const dirt_geometry = new THREE.BoxGeometry(5, 20, 5)
   const dirt_mat = new THREE.MeshStandardMaterial({ color: 0x4d392b })
   const dirt = new THREE.Mesh(dirt_geometry, dirt_mat)
   dirt.position.set(0, -11.5, 0)
   scene.add(dirt)

   const ambient_light = new THREE.HemisphereLight(0xffffff, 0x444444, night_time ? 0.02 : 0.35)
   scene.add(ambient_light)

   const moon = new THREE.DirectionalLight(0xd9f7ff, 0) // wants to be 0.05
   moon.position.set(1.3, 3, 1.9)
   moon.target.position.set(0, 0, 0)
   moon.castShadow = true
   moon.shadow.bias = -0.0005

   const light = new THREE.RectAreaLight(0xffbc5e, 0, 0.35, 0.35) // wants to be 15
   light.position.set(0.45, 0.6, 1)
   light.rotation.y = degrees_to_radians(180)

   const point = new THREE.PointLight(0xffbc5e, 0, 1) // wants to be 1
   point.position.set(0.5, 0.6, 1.1)

   const sun = new THREE.DirectionalLight(0xffffff, 1) // wants to be 1
   sun.position.set(1.3, 3, 1.9)
   sun.target.position.set(0, 0, 0)
   sun.castShadow = true
   sun.shadow.bias = -0.0005

   scene.add(moon)
   scene.add(moon.target)
   scene.add(point)
   scene.add(light)
   scene.add(sun)
   scene.add(sun.target)

   function degrees_to_radians(degrees) {
      var pi = Math.PI;
      return degrees * (pi / 180);
   }

   camera.position.set(-21, 17, 28) //facing right
   // camera.position.set(21, 15, 30)

   camera.lookAt(0, -2, 0)

   const camera_group = new THREE.Group();
   camera_group.position.set(0, 0, 0)
   camera_group.add(camera)

   scene.add(camera_group)

   function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
   }
   animate();

   var particles = []

   var start_x;
   var start_camera_rot;

   const on_drag_start = (x) => {
      start_x = x
      start_camera_rot = camera_group.rotation.y
   }

   const on_drag_end = () => {
      start_x = null
   }

   const on_drag = (x) => {
      if (start_x) camera_group.rotation.y = start_camera_rot + (start_x - x) * 0.01
   }

   renderer.domElement.addEventListener("mousedown", (e) => on_drag_start(e.clientX))
   renderer.domElement.addEventListener("mouseup", on_drag_end)
   renderer.domElement.addEventListener("mousemove", (e) => on_drag(e.clientX))
   document.body.addEventListener("touchstart", (e) => on_drag_start(e.changedTouches[0].pageX))
   document.body.addEventListener("touchmove", (e) => on_drag(e.changedTouches[0].pageX))
   document.body.addEventListener("touchend", on_drag_end)

   // temp just to make it nightime always
   ambient_light.intensity = 0.05;
   moon.intensity = 0.35;
   light.intensity = 20;
   point.intensity = 1;
   sun.intensity = 0;
   

   function tick() {
      if (night_timer < 1) {
         // night_timer += 0.1 // this is for slowly animating it
         night_timer = 1;

         // ambient_light.intensity = THREE.MathUtils.lerp(ambient_light.intensity, night_time ? 0.02 : 0.35, night_timer)
         // moon.intensity = THREE.MathUtils.lerp(moon.intensity, night_time ? 0.05 : 0, night_timer)
         // light.intensity = THREE.MathUtils.lerp(light.intensity, night_time ? 15 : 0, night_timer)
         // point.intensity = THREE.MathUtils.lerp(point.intensity, night_time ? 1 : 0, night_timer)
         // sun.intensity = THREE.MathUtils.lerp(sun.intensity, night_time ? 0 : 1, night_timer)

         if (house) {
            let window = house.children[0].children[3].material
            window.emissive.lerp(night_time ? window_glow_color : window_original_color, night_timer)
            window.emissiveIntensity = THREE.MathUtils.lerp(window.emissiveIntensity, night_time ? 1 : 0, night_timer)
         }
      } else {
         if (night_time) {
            if (light) light.intensity = random_range(10, 30)
            if (point) point.intensity = random_range(0.5, 1.5)
         }
      }

      if (Math.random() < 0.2) {
         add_particle()
      }

      particles.forEach(p => {
         p.position.y += 0.1
         // -= -= for right facing
         p.position.x -= 0.01 + 0.09 * (p.position.y / 10.0)
         p.position.z -= 0.025 + 0.01 * (p.position.y / 10.0)
         p.material.opacity -= 0.04
         p.scale.x += 0.06
         p.scale.y += 0.06
         p.scale.z += 0.06
         p.rotation.y += degrees_to_radians(1)
         if (p.position.y > 10) {
            p.geometry.dispose()
            p.material.dispose()
            scene.remove(p)
            particles = particles.filter(i => i != p)
         }
      })
   }

   function random_from_array(array) {
      return array[Math.floor(Math.random() * array.length)]
   }

   function random_range(min, max) {
      return Math.random() * (max - min) + min
   }

   function add_particle() {
      const particle_colors = [
         0xbdbdbd,
         0xa8a8a8,
         0x9e9e9e,
         0x858585,
         0x595959
      ]

      let size = random_range(0.2, 0.3)
      const geometry = new THREE.BoxGeometry(size, size, size)
      const mat = new THREE.MeshStandardMaterial({ color: random_from_array(particle_colors), transparent: true })
      const cube = new THREE.Mesh(geometry, mat)
      cube.position.set(-0.7, 2.3, -0.25)
      cube.rotation.set(
         degrees_to_radians(random_range(0, 360)),
         degrees_to_radians(random_range(0, 360)),
         degrees_to_radians(random_range(0, 360)),

      )
      cube.castShadow = false
      scene.add(cube)

      particles.push(cube)
   }

   setInterval(tick, 100)

})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.house {
   image-rendering: pixelated;
}

.house::before {
   content: "";
   width: 100%;
   height: 100%;
   background: linear-gradient(transparent 35%, var(--color-1) 90%);
   position: absolute;
   pointer-events: none;
}
</style>
