<template>
  <main>
    <div class="panel options" ref="options_el">
        <div class="option about" @click="() => select_option('about')">About</div>
        <div class="option projects" @click="() => select_option('projects')">Projects</div>
        <div class="option right" @click="() => select_option('home')">benjiwong</div>
      </div>
      <div class="panel top about hidden delay" ref="about_el">
        <p>I'm Benjamin Wong, an undergraduate Computer Science student at University of California Irvine. I love developing accessible, efficient, and aesthetically pleasing software—anything from websites to apps to games.</p>
        <p>I can be reached at <a href="mailto:me@benjiwong.com">me@benjiwong.com</a></p>
        <div class="icons">
            <a href="https://github.com/chiyeon" target="__black"><img class="icon" src="./assets/icons/github.webp"/></a>
            <a href="https://chiyeon.itch.io/" target="__black"><img class="icon" src="./assets/icons/itchio.webp"/></a>
            <a href="/resume.pdf" target="__black"><img class="icon" src="./assets/icons/paper.webp"/></a>
        </div>
        <!-- <p>I also have a <a href="https://film.benjiwong.com">video website.</a></p> -->
        <div class="option" @click="() => select_option('home')">BACK</div>
      </div>
      <div class="panel top projects hidden delay" ref="projects_el">
        <div class="projects-box">
          <ProjectListing
            v-for="project in projects"
            :key="project.name"
            :selected="projects.indexOf(project) == selected_project"
            :project="project"
            :index="projects.indexOf(project)"
            :style="'transition-delay: ' + projects.indexOf(project) * 200 + 'ms;'"

            @select_project="select_project"
          />
        </div>
        <div class="option" @click="() => select_option('home')">BACK</div>
      </div>
      <HouseDiorama />
  </main>
</template>

<script setup>
import HouseDiorama from "./components/HouseDiorama.vue"
import ProjectListing from "./components/ProjectListing.vue"
import { projects } from "./assets/projects.json"
import { ref } from "vue"

const options_el = ref(null)
const about_el = ref(null)
const projects_el = ref(null)
const selected_project = ref(null);

// sob skull coffin
const select_option = (option) => {

   document.querySelector(".option.about").classList.remove("selected");
   document.querySelector(".option.projects").classList.remove("selected");

  if (option == "home") {
    //options_el.value.classList.add("delay");
    //options_el.value.classList.remove("hidden");

    document.querySelector("#house").classList.remove("blurred")
    
    about_el.value.classList.remove("delay");
    about_el.value.classList.add("hidden");
    projects_el.value.classList.remove("delay");
    projects_el.value.classList.add("hidden");
    // contact_el.value.classList.add("hidden");
  } else {
    //options_el.value.classList.remove("delay");
    //options_el.value.classList.add("hidden");

    if (option == "about") {
      document.querySelector(".option.about").classList.add("selected")
      document.querySelector("#house").classList.remove("blurred")
      about_el.value.classList.add("delay");
      about_el.value.classList.remove("hidden");
      projects_el.value.classList.remove("hidden");
      projects_el.value.classList.add("hidden");
    } else if (option == "projects") {
      selected_project.value = 0
      document.querySelector(".option.projects").classList.add("selected");
      document.querySelector("#house").classList.add("blurred")
      projects_el.value.classList.add("delay");
      projects_el.value.classList.remove("hidden");
      about_el.value.classList.add("hidden");
    }
  }
}

const select_project = (index, obj) => {
   selected_project.value = index;

   // timeout needed to finish opening animation
   setTimeout(() => document.querySelector(".projects-box").scrollTo({ top: obj.offsetTop - 10, behavior: "smooth" }), 100);
}

</script>

<style>

@media (max-height: 670px) {
   .house {
      height: 75% !important;
      overflow: hidden;
   }
}

:root {
  --color-1: #1c1b19;
  --color-2: #c0c0c0;
  --color-3: #f7dc88;
  --color-4: #a4a4a4;
  --color-5: #8db473;
  --color-6: #707070;

  --max-width: 670px;
}

@font-face {
   font-family: "Nimbus Sans";
   src: url(assets/NimbusSanL-Reg.otf);
}

@font-face {
   font-family: "Nimbus Sans";
   font-weight: bold;
   src: url(assets/NimbusSanL-Bol.otf);
}

@font-face {
  font-family: "Cocogoose";
  src: url(assets/cocogoose.ttf);
}

html, body {
   overflow-x: hidden;
}

#app,
body {
  margin: 0;
  overflow-x: hidden;

}

body {
  background-color: var(--color-1);
}

h1, h2, h3, a, p {
  color: var(--color-2);
  font-family: "Nimbus Sans";
}

main {
  max-width: var(--max-width);
  padding: 0 10px;
  margin: auto;
  overflow-x: hidden;
}

.home {
  height: 100vh;
}

.house {
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  overflow-x: hidden;
  z-index: -1;
}

.panel {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  max-width: var(--max-width);
  width: 90%;

  transition: 1s top cubic-bezier(0, 1, 0, 1), 1s opacity cubic-bezier(0, 1, 0, 1);
}

.delay {
  transition-delay: 0.2s;
}

.panel.hidden {
  top: 20%;
  opacity: 0;
  pointer-events: none;
}

.panel.top.hidden {
  top: 30%;
}

.options {
  gap: 1em;
  display: flex;

  top: 20px;
}

.option,
.options > * {
  font-family: "Nimbus Sans";
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1em;
  color: var(--color-2);
  width: fit-content;
}

.option:not(.right).selected {
   color: var(--color-3);
}

.right {
   margin-left: auto;
   text-align: right;
   color: var(--color-6);
}

.option:hover {
  color: var(--color-3);
  cursor: pointer;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;

  top: 22%;
}

.projects {
  top: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.projects.hidden {
  top: 10% !important;
}

.hidden .project {
  opacity: 0;
}

.project {
  transition: 1s opacity;
}

.projects-box {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 85vh;
  width: 100%;
}

/* .projects-box::before {
  position: absolute;
  width: 100%;
  height: 80vh;
  content: "";
  background: linear-gradient(var(--color-1) 0%, transparent 10%, transparent 90%, var(--color-1) 100%);
  pointer-events: none;
} */

.project:first-child {
  margin-top: 5em;
}
.project:last-child {
  margin-bottom: 5em;
}

.house {
  transition: 1s opacity, 1s filter;
}

.house.blurred {
  opacity: 0.35;
  filter: blur(3px);
}

.icons {
   margin-bottom: 10px;
}

.icons .icon {
   width: 28px;
   margin-right: 6px;
}

.icons .icon:hover {
   opacity: 0.5;  
}



</style>
