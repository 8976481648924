<template>
   <div ref="project_el" :class="selected ? 'project' : 'project minimized'" @click="select_project">
      <p class="date">{{ project.date }}</p>
      <div class="title-box">
         <h2 class="title">{{ project.title }}</h2>
         <hr />
         <p class="tagline">{{ project.tagline }}</p>
      </div>
      <div :class="selected ? 'content' : 'content hidden'">
         <img :src="get_image(project.images[0])" class="image" />
         <div class="info">
            <div class="left">
               <h3>View On</h3>
               <div class="links">
                  <a
                     v-for="link in project.links"
                     :key="link.url"
                     :href="link.url"
                     target="__blank"
                     ><img class="icon" :src="get_icon(link.type + '.webp')" /></a>
               </div>
            </div>
            <div class="right">
               <h3>Powered By</h3>
               <div class="tools">
                  <p
                     v-for="tool in project.tools"
                     :key="tool"
                  >
                     {{tool + (tool == project.tools[project.tools.length - 1] ? '' : ',')}}
                  </p>
               </div>
            </div>
         </div>
         <p class="description">{{ project.description }}</p>
      </div>
   </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue"
const images = require.context("@/assets/", false, /\.png$|\.jpg$|\.webp$/)
const icons = require.context("@/assets/icons/", false, /\.png$|\.jpg$|\.webp$/)
const project_el = ref(null)

const get_icon = (file_name) => {
   return icons("./" + file_name);
}

const props = defineProps([
   "project",
   "selected",
   "index"
])

const emit = defineEmits([
   "select_project"
])

const get_image = (file_name) => {
   return images("./" + file_name);
}

const select_project = () => {
   emit("select_project", props.index, project_el.value);
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
   .title {
      font-size: 3em !important;
   }
}

p {
   margin: 0;
}

.project {
   margin-bottom: 1em;
}

.project.minimized {
   cursor: pointer;
}

.project.minimized:hover .title::before,
.project:not(.minimized) .title::before {
   font-family: "Cocogoose";
   font-weight: normal;
   content: ">";
   line-height: 0;
   font-size: 1.2em;
   position: relative;
   bottom: 0.045em;
   color: var(--color-3);
}

.title-box {
   height: fit-content;
   position: relative;
}

.title {
   margin: 0;
   text-transform: uppercase;
   font-size: 4em;
   font-weight: normal;
}

.date, .tagline {
   color: var(--color-4);
}

.tagline {
   text-transform: lowercase;
   position: relative;
}

.content > * {
   transition: 
      1s opacity cubic-bezier(0, 1, 0, 1)
      , 1s height cubic-bezier(0, 1, 0, 1)
      , 1s margin cubic-bezier(0, 1, 0, 1);
}

.content.hidden > * {
   opacity: 0;
   height: 0;
   margin: 0;
}

.description {
   margin-bottom: 3em;
   margin-top: 1em;
}

.image {
   width: 100%;
   height: auto;
   border-radius: 10px;
   margin-top: 1em;
   /* margin-bottom: 1em; */

   aspect-ratio: 1.75/1;
   object-fit: cover;
}

/* hr {
   margin-top: 30px;
} */

hr {
   margin-bottom: 0;
   position: relative;
   top: -0.9em;
}

.links {
}

.links .icon {
   width: 28px;
}

.links .icon:hover {
   opacity: 0.5;
}

.links a {
   color: var(--color-5);
   margin-right: 0.5em;
   text-decoration: none;
}

.links a:hover {
   text-decoration: underline;
}

.info h3 {
   margin-bottom: 4px;
}

.info {
   display: flex;
}

.info > * {
   flex: 1;
}

.info .right {
   text-align: left;
}

.info .tools {
   display: flex;
   gap: 6px;
   margin-top: 0.7em;
   flex-wrap: wrap;
}
</style>
